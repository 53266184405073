export const tableProperties = [
  {
    label: '公司抬头',
    prop: 'cptt_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '录入人',
    prop: 'stff_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '外销经办人',
    prop: 'custStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 200,
    needOtherColumn: true,
    multiple: true,
    collapseTags: true,
    formatter: row => row.cust_stff_name
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '目的国',
    prop: 'cust_edcountry',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '目的港',
    prop: 'cust_dport',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '离港日',
    prop: 'tran_dpdate',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 180
  },
  {
    label: '装运日期',
    prop: 'tran_spdate',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 180
  },
  {
    label: '出运数量',
    prop: 'prod_ship',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140
  },
  {
    label: '主要供应商',
    prop: 'supp_abbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '客户订单号',
    prop: 'scon_cust_nos',
    itemType: 'input',
    input: true,
    sortable: true,
    labelWidth: 140
  },
  {
    label: '总体积',
    prop: 'prod_tvol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '总毛重',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '单价',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '币种',
    prop: 'cust_currency',
    itemType: 'select',
    options: [
      { value: 'USD', label: 'USD' },
      { value: 'RMB', label: 'RMB' }
    ],
    input: true,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '出运金额',
    prop: 'prod_subtotal',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 140
  },
  {
    label: '货代机构',
    prop: 'inst_cname',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 140
  }
];
