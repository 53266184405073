<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          class="vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="monthrange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="离港开始日期"
          end-placeholder="离港结束日期"
        />
        <el-date-picker
          v-model="dateRange2"
          class="vg_mr_8 topDateRangeSearch"
          end-placeholder="装运结束日期"
          range-separator="至"
          size="small"
          type="monthrange"
          start-placeholder="装运开始日期"
          value-format="timestamp"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDataList()">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { tableProperties } from '@/views/StatisticsManagement/SDStatistics/sds';
import { downloadFile, setTime } from '@api/public';
import { tranAPI } from '@api/modules/tran';

export default {
  name: 'SDStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: cloneDeep(tableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDataList();
      this.changeTableProperties();
    },
    async changeTableProperties() {},
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange, true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2, true);
      searchForm.tran_dpdate = searchForm.tran_dpdate ? searchForm.tran_dpdate / 1000 : null;
      searchForm.tran_spdate = searchForm.tran_spdate ? searchForm.tran_spdate / 1000 : null;
      searchForm.custStffNameList = searchForm.custStffNameList?.toString();
      await tranAPI.getTranProdTable(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.tableProperties.find(x => x.label === '外销经办人').options = data.custStffNameList;
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange, true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2, true);
      searchForm.tran_dpdate = searchForm.tran_dpdate ? searchForm.tran_dpdate / 1000 : null;
      searchForm.tran_spdate = searchForm.tran_spdate ? searchForm.tran_spdate / 1000 : null;
      let requestBody = { type: 1, ...searchForm };
      tranAPI.exportgetTranProdTable(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs.partMultiTable.resetFields();
      this.dateRange = [];
      this.dateRange2 = [];
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
